import React from "react"
import Hero from '../components/Hero';
import Layout from "../components/shared/layout"
import LayoutContainer from "../components/shared/layoutContent"
import Iframe from '../components/Competition/iframe'
import SEO from "../components/seo"
import { graphql, Link } from 'gatsby'
import Video from '../components/Video';

const Competition = ({ data }) => {
    const { poster, mainImage } = data.allContentfulCompetition.edges[0].node
    return (
        <Layout>
            <SEO
                title="foodSlut Ultimate Burger Battle" mainImage={`https:${poster.fluid.src}`}
                description="foodSlut Stay at home burger battle - we want to see the sluttiest  burger you can rustle up at home! Raising money for Food for London appeal in association with the Felix project."
                url={`/competition`}

            />
            <Hero
                title="foodSlut Stay At Home Burger Battle"
                techStack="Food for London appeal in association with the Felix Project."
                image={mainImage}
                logo={true}
            />
            <LayoutContainer>
                <div className="competition__image-container">
                    <div className="video-container">
                        <Video src="https://player.vimeo.com/video/411537975" />
                    </div>

                </div>

                <div className="">
                    <h2 className="is-size-3"> <strong> RESULTS</strong></h2>
                    <br></br>

       
                    THANK YOU FOR TAKING PART - HERE ARE THE FINAL STANDINGS

                    <br></br>
                    <br></br>

                    <a target="_blank" rel="noopener noreferrer" href="https://uk.virginmoneygiving.com/fundraiser-portal/fundraiserPage?pageId=1164823"><button className='button is-link tab-buttons is-outlined active'>CLICK TO DONATE</button></a>
                    <br></br>
                    <br></br>

                    <br></br>
                    <a target="_blank" rel="noopener noreferrer" href="http://instagram.com/foodslut"> <button className="instagram">FOLLOW US</button></a>
                    <br></br>
                    <br></br>
             
                    <br></br>
                    <Iframe src="https://airtable.com/embed/shrG9XtHtarhJ43m0?backgroundColor=gray&viewControls=on" />


  

                </div>
                <p>For full competition terms and conditions head  <Link to="/competition-terms-and-conditions">here</Link></p>
            </LayoutContainer>
        </Layout>
    )
}

export default Competition

export const query = graphql`
query{
    allContentfulCompetition {
        edges {
          node {
            id
            title
            poster {
                fluid(maxWidth: 500, maxHeight:500){
                  src
                ...GatsbyContentfulFluid
              }
            }
            mainImage {
                fluid(maxWidth: 100, maxHeight:100){
                  src
                ...GatsbyContentfulFluid
              }
            }

            
          }
        }
  }
}`






import React from 'react';

function Iframe({ src }) {

  return (

<iframe title="airtable" className="airtable-embed" src={src} frameBorder="0" width="100%" height="800" ></iframe>
  );
}

export default Iframe;
